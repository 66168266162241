import React from "react";
// react component for creating beautiful carousel
import Carousel from "react-slick";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import classNames from "classnames";
// @material-ui/icons
import FormatQuote from "@material-ui/icons/FormatQuote";
import Star from "@material-ui/icons/Star";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import CardAvatar from "components/Card/CardAvatar.jsx";
import Muted from "components/Typography/Muted.jsx";
import Warning from "components/Typography/Warning.jsx";
import Settings from "@material-ui/icons/Settings";

import testimonialsStyle from "assets/jss/material-kit-pro-react/views/landingPageSections/testimonialsStyle.jsx";

import dg2 from "assets/img/dg2.jpg";
import cardProfile1Square from "assets/img/faces/card-profile1-square.jpg";
import cardProfile2Square from "assets/img/faces/card-profile2-square.jpg";
import cardProfile4Square from "assets/img/faces/card-profile4-square.jpg";
import cardProfile6Square from "assets/img/faces/card-profile6-square.jpg";
import kendall from "assets/img/faces/kendall.jpg";
import christian from "assets/img/faces/christian.jpg";

function SectionTestimonials({ ...props }) {
  const { classes, ...rest } = props;
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true
  };
  return (
    <div className="cd-section" {...rest}>
      <div
        className={`${classes.testimonials} ${classes.sectionDark} ${
          classes.testimonial2
        }`}
      >
        <div className={classes.container}>
          <GridContainer>
            <GridItem md={12}>
              <div className={classes.sectionDescription}>
                <h2 className={classes.title}>
                  <Settings />
                  Construcción de piscinas
                </h2>
                <h5 className={classes.description}>
                  En Piscines Cosgaya sabemos que una piscina es algo más que un
                  lugar para bañarnos. Es un lugar para relajarnos, disfrutar de
                  la familia, evadirnos y despreocupar-nos de todo. Por eso, en
                  Piscines Cosgaya diseñamos y construimos su piscina a medida.
                  Analizando diferentes factores técnicos y estéticos como el
                  tipo de terreno, el entorno y sus gustos, hacemos realidad su
                  piscina de ensueño con las mejores garantías.
                </h5>
              </div>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <Carousel {...settings}>
                <div>
                  <Card testimonial plain className={classes.card2}>
                    <CardAvatar testimonial plain>
                      <img src={require("assets/img/pipelines.png")} />
                    </CardAvatar>
                    <h4 className={classes.cardTitle}>Sistemas hidráulicos</h4>
                    <CardBody plain>
                      <h5 className={classes.cardDescription}>
                        Realizamos todos los cálculos para diseñar y construir
                        un sistema hidráulico que sea hidrodinámicamente
                        eficiente y que nos ayude con el posterior mantenimiento
                        del agua de la piscina. Realizamos cálculos para que la
                        velocidad del agua en las tuberías sea la más eficiente,
                        también calculamos la velocidad de filtración en el
                        filtro y realizamos la instalación para que esta no esté
                        en el límite máximo y el filtrado sea óptimo. En caso de
                        piscinas desbordantes se calculan dos o más circuitos
                        hidráulicos, dependiendo del volumen de agua a
                        recircular, para que el desborde sea correcto.
                      </h5>
                    </CardBody>
                  </Card>
                </div>
                <div>
                  <Card testimonial plain className={classes.card2}>
                    <CardAvatar testimonial plain>
                      <img src={require("assets/img/ph.png")} />
                    </CardAvatar>
                    <h4 className={classes.cardTitle}>Tratamiento del agua</h4>
                    <CardBody plain>
                      <h5 className={classes.cardDescription}>
                        Somos expertos en el tratamiento del agua y disponemos
                        de sistemas para el control y regulación del
                        desinfectante automático, así como para el control y
                        regulación del pH, también instalamos sistemas de
                        electrólisis salina “piscinas de sal” y sistemas de
                        desinfección alternativa del agua. Con esto usted se
                        olvidará de los incómodos productos químicos y podrá
                        controlar y gestionar el sistema fácilmente. ​
                      </h5>
                    </CardBody>
                  </Card>
                </div>
                <div>
                  <Card testimonial plain className={classes.card2}>
                    <CardAvatar testimonial plain>
                      <img src={require("assets/img/automation.png")} />
                    </CardAvatar>
                    <h4 className={classes.cardTitle}>Automatización</h4>
                    <CardBody plain>
                      <h5 className={classes.cardDescription}>
                        En caso de querer un nivel superior de automatización,
                        le ofrecemos la posibilidad de domotizar todo el
                        sistema, pudiendo usted parametrizar y controlar desde
                        el móvil o la tablet cualquiera de los parámetros de la
                        piscina tal y como las horas de filtración, el pH al que
                        queremos el agua, la temperatura, programar los lavados
                        del filtro, encender y apagar las luces y/o las fuentes
                        de agua y un sinfín más de acciones asociadas a la
                        piscina. ​
                      </h5>
                    </CardBody>
                  </Card>
                </div>
                <div>
                  <Card testimonial plain className={classes.card2}>
                    <CardAvatar testimonial plain>
                      <img src={require("assets/img/acabados.png")} />
                    </CardAvatar>
                    <h4 className={classes.cardTitle}>Diferentes acabados</h4>
                    <CardBody plain>
                      <h5 className={classes.cardDescription}>
                        En el acabado del vaso disponemos diferentes variantes
                        como el gresite, la lámina armada o, si usted lo desea,
                        también podemos instalarle un vaso prefabricado de
                        poliéster. ​
                      </h5>
                    </CardBody>
                  </Card>
                </div>
              </Carousel>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}

export default withStyles(testimonialsStyle)(SectionTestimonials);
