import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import GoogleMaps from 'components/GoogleMaps/GoogleMaps.jsx';

// nodejs library that concatenates classes
import classNames from 'classnames';
// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import InfoArea from 'components/InfoArea/InfoArea.jsx';


import Chat from '@material-ui/icons/Chat';
import VerifiedUser from '@material-ui/icons/VerifiedUser';
import LocalFlorist from '@material-ui/icons/LocalFlorist';

import withStyles from '@material-ui/core/styles/withStyles';

import styles from './SectionContactStyles.jsx';
import './SectionContact.css';

class SectionContact extends React.Component {
  goToContact() {
    let target = document.getElementById('contact-info');
    target.scrollIntoView({
      block: 'start',
      behavior: 'smooth'
    });
    document.activeElement.blur();
  }

  render() {
    const { classes } = this.props;

    return (
      <div id="contact" className="landscape-section-contact">
        <div className={classNames(
          classes.features1,
          'landscape-section-contact-why-us'
        )}>
          <GridContainer>
            <GridItem
              xs={12}
              sm={8}
              md={8}
              className={`${classes.mlAuto} ${classes.mrAuto}`}
            >
              <h2 className={classes.title}>¿Por qué elegirnos?</h2>
              <h5 className={classes.description}>
                Somos una empresa joven, dinámica y con un
                objetivo muy claro, la satisfacción de nuestros clientes.
                Ofrecemos un servicio completo de todo lo que pueda necesitar en
                su piscina y entorno de la misma.
              </h5>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={4}>
              <InfoArea
                vertical
                icon={() => <i className="fa fa-map-marker-alt" />}
                title="Llegamos donde estés"
                description=""
                iconColor="info"
              />
              <div className="landscape-section-contact-why-us-text">
                <p>
                  Nos encontramos en Sant Celoni, en el Vallés Oriental pero
                  construimos, rehabilitamos y optimizamos piscinas por todo
                  Cataluña.
                </p>
                <p>Ofrecemos servicio de mantenimientos ordinarios en toda
                la zona del Vallès Oriental, Vallès Occidental, Barcelonès,
                Maresme, La Selva, Pla de l'Estany, Baix Empordà, Alt Empordà,
                Gironès, Baix Llobregat, Garraf y Alt Penedès.
                </p>
              </div>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <InfoArea
                className="landscape-section-contact-why-us-quality"
                vertical
                icon={VerifiedUser}
                title="Productos de calidad"
                description=""
                iconColor="success"
              />
              <div className="landscape-section-contact-why-us-text">
                <p>
                  Utilizamos productos de primera calidad, buscando siempre un buen acabado.
                  Buscamos siempre la satisfacción del cliente mediante intervenciones profesionales
                  con materiales de primera calidad y un precio muy competitivo.
                </p>
                <p>
                  Tanto en la construcción, mantenimiento y optmización de piscinas utilizamos productos de primeras marcas y ya contrastados.
                </p>
                <p>
                  Si quiere estar tranquilo, tener un servicio excelente y que no le tomen el pelo, <a className="go-to-contact-link" onClick={() => this.goToContact()}>contacte con nosotros</a>.
                  </p>
              </div>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <InfoArea
                className="landscape-section-contact-why-us-health"
                vertical
                icon={LocalFlorist}
                title="Respeto al medio ambiente"
                description=""
                iconColor="danger"
              />
              <div className="landscape-section-contact-why-us-text">
                <p>
                  Estamos comprometidos con el medio ambiente, por eso en nuestros mantenimientos e
                  intervenciones fisicoquímicas siempre utilizamos el mínimo producto químico y
                  asesoramos al cliente para que pueda optimizar su piscina para que, tanto ese consumo como el de agua sea el mínimo posible.
                </p>
                <p>
                  Los productos que utilizamos estan sujetos a las normativas más exigentes de la UE y
                  nuestras técnicas para usarlos són las mejores para su correcto funcionamiento.
                  Nos formamos año tras año para que esto siga siendo así siempre.
                </p>
                <p>
                  También asesoramos al cliente para reducir el consumo eléctrico de la instalación y/o
                  para la instalación de sistemas más eficientes o sistemas en los que el propio cliente
                   pueda autoabastecerse de su propia electricidad gracias a la energia solar.
                  </p>
              </div>
            </GridItem>
          </GridContainer>
        </div>
        <div
          id="contact-info"
          className={classNames(
            classes.socialLine,
            classes.textCenter,
            classes.white,
            classes.bigIcons,
            'landscape-section-contact-logos'
          )}
        >
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={2} md={2} className={classNames(classes.border, 'landscape-section-contact-logos-item')}>
                <Button
                  color="facebook"
                  justIcon
                  simple
                  href="#"
                  onClick={(e) => { e.preventDefault(); window.open('tel:+34626515828', '_self'); }}
                >
                  <i className="fa fa-phone" />
                  <span className="icon-text">626 51 58 28</span>
                </Button>
              </GridItem>
              <GridItem xs={12} sm={2} md={2} className={classNames(classes.border, 'landscape-section-contact-logos-item')}>
                <Button
                  color="instagram"
                  justIcon
                  simple
                  href="#"
                  onClick={(e) => { e.preventDefault(); window.open('https://www.instagram.com/piscinescosgaya_/', '_blank'); }}
                >
                  <i className="fab fa-instagram" />
                  <span className="icon-text small">Instagram</span>
                </Button>
              </GridItem>
              <GridItem xs={12} sm={2} md={2} className={classNames(classes.border, 'landscape-section-contact-logos-item')}>
                <Button
                  color="facebook"
                  justIcon
                  simple
                  href="#"
                  onClick={(e) => { e.preventDefault(); window.open('https://api.whatsapp.com/send?phone=34626515828', '_blank'); }}
                >
                  <i className="fab fa-whatsapp" />
                  <span className="icon-text small">WhatsApp</span>
                </Button>
              </GridItem>
              <GridItem xs={12} sm={2} md={2} className={classNames(classes.border, 'landscape-section-contact-logos-item')}>
                <Button
                  color="facebook"
                  justIcon
                  simple
                  href="#"
                  onClick={(e) => { e.preventDefault(); window.open('http://t.me/RGCosgaya', '_blank'); }}
                >
                  <i className="fab fa-telegram" />
                  <span className="icon-text small">Telegram</span>
                </Button>
              </GridItem>
              <GridItem xs={12} sm={2} md={2} className={classNames(classes.border, 'landscape-section-contact-logos-item')}>
                <Button
                  color="facebook"
                  justIcon
                  simple
                  href="#"
                  onClick={(e) => { e.preventDefault(); window.open('mailto:info@piscinescosgaya.com', '_self'); }}
                >
                  <i className="fa fa-envelope" />
                  <span className="icon-text small">info@piscinescosgaya.com</span>
                </Button>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(SectionContact);
