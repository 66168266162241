import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import GoogleMaps from "components/GoogleMaps/GoogleMaps.jsx";

import { withTranslation } from 'react-i18next';

// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import pool from "assets/img/swimming_pool5.jpg";

import withStyles from "@material-ui/core/styles/withStyles";

import styles from "./SectionOfertasStyles.jsx";
import "./SectionOfertas.css";

class SectionOfertas extends React.Component {
  goToContact() {
    let target = document.getElementById("contact-info");
    target.scrollIntoView({
      block: "start",
      behavior: 'smooth'
    });
    document.activeElement.blur();
  }

  render() {
    const { classes, t } = this.props;

    return (
      <div id="pricing" className="landscape-section-pricing">
        <div
          className={`${classes.pricing} ${classes.section}`}
          style={{ backgroundImage: `url(${pool})` }}
        >
          <div className={classes.container}>
            <GridContainer>
              <GridItem
                xs={12}
                sm={6}
                md={6}
                className={`${classes.mlAuto} ${classes.mrAuto} ${
                  classes.textCenter
                  }`}
              >
                <h2 className={classes.title}>Descubre nuestras promociones</h2>
                <h5 className={classes.description}>

                </h5>
                <div className={classes.sectionSpace} />
              </GridItem>
            </GridContainer>
            <GridContainer className="landscape-section-pricing-cards">
              {/*<GridItem xs={12} sm={4} md={4} className={classes.mlAuto}>
                <Card pricing>
                  <CardBody pricing>
                    <h6 className={classes.cardDescription}>{t("promotion")}</h6>
                    <h1 className={classes.cardTitle}>
                      <small></small>500 <small>€/mes</small>
                    </h1>
                    <ul>
                      <li>
                        <b>1</b> Limpieza mensual
                    </li>
                      <li>
                        <b>5</b> Team Members
                    </li>
                      <li>
                        <b>55</b> Personal Contacts
                    </li>
                      <li>
                        <b>5.000</b> Messages
                    </li>
                    </ul>
                  </CardBody>
                  <CardFooter pricing className={classes.justifyContentCenter}>
                    <Button color="success" round>
                      Llámanos ya
                  </Button>
                  </CardFooter>
                </Card>
                </GridItem> */}
              <GridItem xs={12} sm={4} md={4} className={classes.mrAuto}>
                <Card pricing color="success">
                  <CardBody pricing>
                    <h6
                      className={`${classes.cardCategory} ${
                        classes.marginBottom30
                        }`}
                    >
                      PISCINAS DE SAL
                  </h6>
                    <h1 className={classes.cardTitleWhite}>
                      <small className="smallest">desde</small> 800 <small>€</small>
                    </h1>
                    <ul>
                      <li>
                        <b></b> Olvídate del cloro
                    </li>
                      <li>
                        <b></b> Nos ocupamos de todo
                    </li>
                      <li>
                        <b></b> Todo incluido, sin sorpresas al final
                    </li>
                    </ul>
                  </CardBody>
                  <CardFooter pricing className={classes.justifyContentCenter}>
                    <Button color="white" round onClick={() => this.goToContact()}>
                      Llámanos ya
                  </Button>
                  </CardFooter>
                </Card>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(withStyles(styles)(SectionOfertas));
