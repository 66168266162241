import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import Parallax from "components/Parallax/Parallax.jsx";

import promosPageStyle from "assets/jss/material-kit-pro-react/views/promosPageStyle.jsx";

// Sections for this page
import SectionInfo from "./Sections/SectionInfo.jsx";
import SectionPricing from "./Sections/SectionPricing.jsx";

const dashboardRoutes = [];

class PromosPage extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }

  showPromo = () => {
    this.props.history.push("/promos");
  };

  render() {
    const { classes, ...rest } = this.props;
    return (
      <div>
        <Header
          color="transparent"
          routes={dashboardRoutes}
          brand="Piscines Cosgaya"
          links={<HeaderLinks dropdownHoverColor="info" />}
          fixed
          changeColorOnScroll={{
            height: 150,
            color: "headerLogo"
          }}
          {...rest}
        />
        <Parallax
          image={require("assets/img/swimming_pool2.jpg")}
          filter="dark"
          className={classes.parallax}
        >
          <div className={classes.container} />
        </Parallax>
        <div className={classNames(classes.main, classes.mainRaised)}>
          <div>
            <SectionInfo />
            <SectionPricing />
          </div>
        </div>
        <Footer
          content={
            <div>
              <div className={classes.center}>
                &copy; {1900 + new Date().getYear()} Piscines Cosgaya
              </div>
            </div>
          }
        />
      </div>
    );
  }
}

export default withStyles(promosPageStyle)(PromosPage);
