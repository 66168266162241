import React from "react";
import validator from 'validator';
import axios from 'axios';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import GoogleMaps from "components/GoogleMaps/GoogleMaps.jsx";

// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import SnackbarContent from "components/Snackbar/SnackbarContent.jsx";

import withStyles from "@material-ui/core/styles/withStyles";

import styles from "./SectionFormContactStyles.jsx";
import "./SectionFormContact.css";

class SectionFormContact extends React.Component {

  state = {
    name: "",
    email: "",
    phone: "",
    comments: "",
    showFormError: false,
    showAPISuccess: false,
    showAPIError: false
  }

  async _onSubmit() {
    const { name, email, phone, comments } = this.state;

    let formOK = true;
    if (validator.isEmpty(name)) formOK = false;
    if (validator.isEmpty(email) || !validator.isEmail(email)) formOK = false;
    if (validator.isEmpty(phone)) formOK = false;
    if (validator.isEmpty(comments)) formOK = false;

    this.setState({ showFormError: !formOK, showAPIError: false, showAPISuccess: false });

    if (formOK) {
      try {
        const response = await axios.post('https://admin.piscinescosgaya.com/api/mail/contact/', {
          name: name,
          email: email,
          phone: phone,
          comments: comments,
          hash: "3eofjo289eohwf0hwf23ru9wer12283DSFWRjfhd1erª1jrefe9g"
        });
        const data = response.data;
        if (data.Messages && data.Messages[0] && data.Messages[0].Status === "success") this.setState({ showAPISuccess: true });
        else this.setState({ showAPIError: true });
      }
      catch (error) {
        console.log(error)
        this.setState({ showAPIError: true });
      }
    }
  }

  render() {
    const { classes } = this.props;
    const { name, email, phone, comments, showFormError, showAPISuccess, showAPIError } = this.state;

    return (
      <div className={classNames(classes.section, 'landscape-section-form-contact')}>
        <GridContainer justify="center">
          <GridItem cs={12} sm={8} md={8}>
            <h2 className={classes.title}>Contacta con nosotros</h2>
            <h4 className={classes.description}>
              ¿Tienes dudas? Escríbenos y te contestaremos lo antes posible. Rellena el formulario con tus comentarios!
            </h4>
            <form>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    labelText="Nombre"
                    id="name"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "text",
                      onChange: e => { this.setState({ name: e.target.value }) }
                    }}
                    value={name}
                    success={!validator.isEmpty(name)}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <CustomInput
                    labelText="E-mail"
                    id="email"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "email",
                      onChange: e => { this.setState({ email: e.target.value }) }
                    }}
                    error={!validator.isEmpty(email) && !validator.isEmail(email)}
                    success={!validator.isEmpty(email) && validator.isEmail(email)}
                    value={name}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <CustomInput
                    labelText="Teléfono"
                    id="phone"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "text",
                      onChange: e => { this.setState({ phone: e.target.value }) }
                    }}
                    success={!validator.isEmpty(phone)}
                    value={phone}
                  />
                </GridItem>
                <CustomInput
                  labelText="Tus comentarios"
                  id="message"
                  formControlProps={{
                    fullWidth: true,
                    className: classes.textArea
                  }}
                  inputProps={{
                    multiline: true,
                    rows: 5,
                    onChange: e => { this.setState({ comments: e.target.value }) }
                  }}
                  success={!validator.isEmpty(comments)}
                  value={comments}
                />
                <GridItem
                  xs={12}
                  sm={4}
                  md={4}
                  className={`${classes.mrAuto} ${classes.mlAuto} landscape-section-form-contact-buttons`}
                >
                  <Button id="sendFormButton" color="success" onClick={() => this._onSubmit()}>Enviar</Button>
                </GridItem>
                {showFormError ?
                  <GridItem
                    xs={12}
                    className="landscape-section-form-contact-snackbar"
                  >
                    <SnackbarContent
                      message={
                        <span>
                          <b>ERROR: </b> Rellena los campos correctamente.
          </span>
                      }
                      color="danger"
                      icon="info_outline"
                    />
                  </GridItem> : null}
                {showAPISuccess ?
                  <GridItem
                    xs={12}
                    className="landscape-section-form-contact-snackbar"
                  >
                    <SnackbarContent
                      message={
                        <span>
                          <b>BIEN! </b> El formulario se ha enviado correctamente.
          </span>
                      }
                      color="success"
                      icon="info_outline"
                    />
                  </GridItem> : null}
                {showAPIError ?
                  <GridItem
                    xs={12}
                    className="landscape-section-form-contact-snackbar"
                  >
                    <SnackbarContent
                      message={
                        <span>
                          <b>ERROR: </b> Ha habido un error al enviar el formulario.
          </span>
                      }
                      color="danger"
                      icon="info_outline"
                    />
                  </GridItem> : null}
              </GridContainer>
            </form>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default withStyles(styles)(SectionFormContact);
