import React from 'react';

import classNames from 'classnames';

import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import Info from 'components/Typography/Info.jsx';
import Danger from 'components/Typography/Danger.jsx';
import Success from 'components/Typography/Success.jsx';

import pool1 from 'assets/img/swimming_pool1.jpg';
import pool2 from 'assets/img/swimming_pool2.jpg';
import pool3 from 'assets/img/swimming_pool3.jpg';

import withStyles from '@material-ui/core/styles/withStyles';
import styles from './SectionSolutionsStyles';

import './SectionSolutions.css';

class SectionSolutions extends React.Component {

  render() {
    const { classes, t } = this.props;

    const solutions = t('solutions', { returnObjects: true });

    return (
      <div id="solutions" className="landscape-section-features">
        <div id="piscinesComWidget" />
        <div className={classes.blog}>
          <div className={classNames(classes.container, 'landscape-section-features-container')}>
            <GridContainer>
              <GridItem
                xs={12}
                sm={12}
                md={10}
                className={`${classes.mlAuto} ${classes.mrAuto}`}
              >
                <h2 className={classes.title}>Nuestras soluciones</h2>
                <GridContainer>
                  {solutions.map((solution, index) => (
                    <GridItem xs={12} sm={6} md={4} key={`solution-${index}`} className='grid-item'>
                      <Card plain blog className="card">
                        <CardHeader plain image>
                          <a onClick={e => e.preventDefault()}>
                            <img src={solution.image} alt="..." />
                          </a>
                          <div
                            className={classes.coloredShadow}
                            style={{
                              backgroundImage: `url(${solution.image})`,
                              opacity: '1'
                            }}
                          />
                        </CardHeader>
                        <CardBody plain>
                          <Info>
                            <h6 className={classes.cardCategory}>
                              {solution.title}
                            </h6>
                          </Info>
                          <h4 className={classes.cardTitle}>
                            <a onClick={e => e.preventDefault()}>
                              {solution.subtitle}
                            </a>
                          </h4>
                          <p className={classes.description} dangerouslySetInnerHTML={{ __html: solution.text }}>
                            {/*<a onClick={e => e.preventDefault()}>
                              {" "}
                              Leer más{" "}
                            </a>*/}
                          </p>
                          {solution.items ?
                            <ul className="landscape-section-features-items">
                              {solution.items.map((item, i) => (
                                <li key={`solution-${index}-item-${i}`}>
                                  <FontAwesomeIcon icon={['fas', 'check']} />
                                  {item}
                                </li>
                              ))}
                            </ul>
                            : null}
                          {solution.text2 ? <p className={classes.description} dangerouslySetInnerHTML={{ __html: solution.text2 }} /> : null}
                        </CardBody>
                      </Card>
                    </GridItem>
                  ))}
                </GridContainer>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(withStyles(styles)(SectionSolutions));
