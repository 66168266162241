/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Build from "@material-ui/icons/Build";
import Subject from "@material-ui/icons/Subject";
import FormatPaint from "@material-ui/icons/FormatPaint";
import Code from "@material-ui/icons/Code";
import Dashboard from "@material-ui/icons/Dashboard";
import Timeline from "@material-ui/icons/Timeline";
import Group from "@material-ui/icons/Group";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import NavPills from "components/NavPills/NavPills.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Muted from "components/Typography/Muted.jsx";
import InfoArea from "components/InfoArea/InfoArea.jsx";
import Badge from "components/Badge/Badge.jsx";

import projectsStyle from "assets/jss/material-kit-pro-react/views/landingPageSections/projectsStyle.jsx";

import office2 from "assets/img/examples/office2.jpg";
import cardBlog3 from "assets/img/examples/card-blog3.jpg";
import cardProject1 from "assets/img/examples/card-project1.jpg";
import cardProject2 from "assets/img/swimming_pool9.jpg";
import cardProject3 from "assets/img/examples/card-project3.jpg";
import cardProject4 from "assets/img/examples/card-project4.jpg";
import cardProject5 from "assets/img/examples/card-project5.jpg";
import cardProject6 from "assets/img/examples/card-project6.jpg";

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function SectionProjects({ ...props }) {
  const { classes, onPromoClicked, ...rest } = props;
  return (
    <div className="cd-section" {...rest}>
      <div className={`${classes.projects} ${classes.projects4}`}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem
              xs={12}
              sm={8}
              md={8}
              className={`${classes.mlAuto} ${classes.mrAuto} ${
                classes.textCenter
              }`}
            >
              <h2 className={classes.title}>
                Promociones
              </h2>
              <h5 className={classes.description}>
                Aproveche nuestras promociones para obtener nuestros servicios al mejor precio. Si tiene alguna duda no dude en contactarnos.
              </h5>
              <div className={classes.sectionSpace} />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={5} className={classes.mlAuto}>
              <Card
                background
                className={classes.card4}
                style={{ backgroundImage: `url(${cardProject2})` }}
                onClick={onPromoClicked}
              >
                <CardBody background className={classes.cardBody4}>
                  <a onClick={e => e.preventDefault}>
                    <h3 className={classes.cardTitle}>Descubre nuestras ofertas</h3>
                    <p className={classes.cardDescription}>
                      Haz clic para ver las promociones
                    </p>
                  </a>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={5} className={classes.mrAuto}>
              <InfoArea
                className={classes.info4}
                title="Mano de obra"
                description="Gratis en la instalación de sistemas de electrólisi salina (piscina de sal) y sistemas automatizados de productos químicos."
                icon={()=><FontAwesomeIcon icon={["fas", "paint-roller"]} className={classes.icon} />}
                iconColor="success"
              />
              <InfoArea
                className={classes.info4}
                title="Tratamientos con bromo"
                description="Descuento del 10% al usar este elemento químico mucho más eficiente que el cloro."
                icon={()=><FontAwesomeIcon icon={["fas", "flask-potion"]} className={classes.icon} />}
                iconColor="primary"
              />
              <InfoArea
                className={classes.info4}
                title="Sistema Soft Care"
                description="Descuento del 10% al usar este sistema recomendado para pieles atópicas."
                icon={()=><FontAwesomeIcon icon={["fas", "grin-beam"]} className={classes.icon} />}
                iconColor="rose"
              />
            <InfoArea
                className={classes.info4}
                title="Piscinas de sal"
                description="Descubre nuestras promociones en sistemas de electrólisi salina y olvídate del cloro."
                icon={()=><FontAwesomeIcon icon={["fas", "swimming-pool"]} className={classes.icon} />}
                iconColor="info"
              />
            </GridItem>
          </GridContainer>
          <hr />
        </div>
      </div>
    </div>
  );
}

SectionProjects.propTypes = {
  classes: PropTypes.object.isRequired,
  onPromoClicked: PropTypes.func,
};

export default withStyles(projectsStyle)(SectionProjects);
