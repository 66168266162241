import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import Parallax from "components/Parallax/Parallax.jsx";

import landingPageStyle from "assets/jss/material-kit-pro-react/views/landingPageStyle.jsx";

// Sections for this page
import SectionSolutions from "./Sections/SectionSolutions.jsx";
import SectionTestimonials from "./Sections/SectionTestimonials.jsx";
import SectionProjects from "./Sections/SectionProjects.jsx";
import SectionOfertas from "./Sections/SectionOfertas.jsx";
import SectionContact from "./Sections/SectionContact.jsx";
import SectionFormContact from "./Sections/SectionFormContact.jsx";
import SectionFooter from "./Sections/SectionFooter.jsx";

import logo from "assets/img/piscines_cosgaya_white.png";

import "./LandingPage.css";

const dashboardRoutes = [];

class LandingPage extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }

  showPromo = () => {
    this.props.history.push("/promos");
  };

  goToContent() {
    let target = document.getElementById("solutions");
    target.scrollIntoView({
      block: "start",
      behavior: 'smooth'
    });
    document.activeElement.blur();
  }

  render() {
    const { classes, ...rest } = this.props;
    return (
      <div>
        <Header
          color="transparent"
          routes={dashboardRoutes}
          brand="Piscines Cosgaya"
          links={<HeaderLinks dropdownHoverColor="info" />}
          fixed
          changeColorOnScroll={{
            height: window.innerHeight,
            color: "headerLogo"
          }}
          {...rest}
        />
        <Parallax image={require("assets/img/swimming_pool5.jpg")} className="landscape-parallax">
          <div id="landscape-logo"><img src={logo}></img></div>
          <div className={classes.container}>
            <GridContainer className={classes.parallaxContainer}>
              <GridItem
                xs={12}
                sm={6}
                md={6}
                className={classNames(classes.parallaxGridItem, "landscape-parallax-content")}
              >
                <h4>
                  - Expertos en construcción, mantenimiento y optimización de
                 piscinas -
                </h4>
                <h1
                  className={classNames(classes.title, classes.parallaxTitle)}
                >
                  El mejor aliado <br />
                  para tu piscina
                </h1>
                {/* <br />
                <Button
                  color="info"
                  size="lg"
                  rel="noopener noreferrer"
                  className={classes.parallaxButton}
                  onClick={() => {this.goToContact()}
                >
                  Quiero saber más
                  <i className="far fa-level-down" style={{ marginLeft: 10 }} />
                </Button> */}
              </GridItem>
            </GridContainer>
          </div>
          <div className="landscape-parallax-go-bottom">
            <a onClick={() => this.goToContent()}>
              <i className="fal fa-angle-double-down"></i>
            </a>
          </div>
        </Parallax>
        <div className={classNames(classes.main, classes.mainRaised)}>
          <div>
            <SectionSolutions />
            {/*<SectionTestimonials />*/}
            {/* <SectionProjects onPromoClicked={this.showPromo} /> */}
            <SectionOfertas />
            <SectionContact />
            <SectionFormContact />
            <SectionFooter />
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(landingPageStyle)(LandingPage);
