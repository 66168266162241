import React, { Component } from "react";
import GoogleMapReact from "google-map-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./GoogleMaps.css";

class GoogleMaps extends Component {

	render() {
		return (
			<div id="googleMapsContainer">
				<GoogleMapReact
					bootstrapURLKeys={{ key: "AIzaSyDQud18UaqYzEJG0AjMZ8qnJdnVsfiRZEA" }}
					center={{ lat: 41.821279, lng: 2.734752 }}
					zoom={10}
				>
					<FontAwesomeIcon icon={["fas", "map-marker-alt"]} lat={41.73} lng={2.475} />
					<FontAwesomeIcon icon={["fas", "map-marker-alt"]} lat={42.015} lng={2.805} />
					<FontAwesomeIcon icon={["fas", "map-marker-alt"]} lat={41.855} lng={3.05} />
				</GoogleMapReact>
			</div>
		);
	}
}

export default GoogleMaps;
