import {
  container,
  mlAuto,
  mrAuto,
  title,
  cardTitle,
  description,
  coloredShadow,
  sectionDark
} from "assets/jss/material-kit-pro-react.jsx";

const projectsSection = {
  container,
  mlAuto,
  mrAuto,
  title,
  description,
  coloredShadow,
  cardTitle,
  textCenter: {
    textAlign: "center"
  },
  projects: {
    padding: "80px 0"
  },
  tabSpace: {
    padding: "20px 0 50px 0px"
  },
  cardCategory: {
    color: "rgba(255, 255, 255, 0.7) !important",
    marginTop: "10px"
  },
  cardTitleWhite: {
    ...cardTitle,
    color: "#FFFFFF !important",
    marginTop: "10px !important"
  },
  cardDescription: {
    color: "rgba(255, 255, 255, 0.7) !important"
  },
  sectionSpace: {
    height: "70px",
    display: "block"
  },
  marginTop20: {
    marginTop: "20px"
  },
  card2: {
    textAlign: "center"
  },
  sectionDark: {
    ...sectionDark,
    backgroundSize: "550% 450%",
    "& $title, & $cardTitle": {
      color: "#FFFFFF"
    },
    "& $cardCategory": {
      color: "rgba(255, 255, 255, 0.5) !important"
    },
    "& $cardDescription": {
      color: "rgba(255, 255, 255, 0.76) !important"
    }
  },
  projects3: {
    "& $title": {
      marginBottom: "80px",
      marginTop: "5px"
    },
    "& h6": {
      marginBottom: "5px"
    }
  },
  card4: {
    marginTop: 50,
    marginBottom: "0",
    "& $cardTitle": {
      color: "#FFFFFF"
    }
  },
  cardBody4: {
    paddingTop: "140px",
    paddingBottom: "140px",
    cursor: "pointer",
    transition: "transform 300ms ease",
    "&:hover": {
      transform: "scale(1.3)"
    }
  },
  info4: {
    padding: "0"
  },
  infoFull: {
    width: "100%",
    maxWidth: "inherit",
    padding: 0
  },
  projects4: {
    "& hr": {
      margin: "40px auto",
      maxWidth: "970px"
    }
  },
  icon: {
    fontSize: "32px",
    width: "1.5em !important"
  },
  seeConditions: {
    textAlign: "center",
    margin: "40px 0",
    fontSize: "14px",
    "&:hover": {
      textDecoration: "underline",
      cursor: "pointer"
    },
    "& svg": {
      verticalAlign: "bottom",
      fontSize: "26px",
      marginRight: 10
    }
  },
  closeButton: { margin: "0 auto" }
};

export default projectsSection;
