import React from "react";

import "./SectionFooter.css";

class SectionFooter extends React.Component {
  render() {
    return (
      <div id="footer" className="landscape-section-footer">
        <div>© 2019 Piscines Cosgaya</div>
      </div>
    );
  }
}

export default SectionFooter;
