import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import Weekend from "@material-ui/icons/Weekend";
import Home from "@material-ui/icons/Home";
import Business from "@material-ui/icons/Business";
import AccountBalance from "@material-ui/icons/AccountBalance";
import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Button from "components/CustomButtons/Button.jsx";
import NavPills from "components/NavPills/NavPills.jsx";
import Success from "components/Typography/Success.jsx";
import Danger from "components/Typography/Danger.jsx";

import pricingStyle from "assets/jss/material-kit-pro-react/views/promosPageSections/pricingStyle.jsx";

import bg11 from "assets/img/bg11.jpg";
import city from "assets/img/examples/city.jpg";
import cardBlog3 from "assets/img/examples/card-blog3.jpg";

function SectionPricing({ ...props }) {
  const { classes, ...rest } = props;
  return (
    <div className="cd-section" {...rest}>
      {/* Pricing 4 START */}
      <div className={classes.pricing}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem
              xs={12}
              sm={6}
              md={4}
              lg={2}
              className={classes.pricingItem}
            >
              <Card pricing plain>
                <CardBody pricing plain>
                  <h6 className={classes.cardCategory}>MUY PEQUEÑAS</h6>
                  <h1 className={classes.cardTitle}>
                    <small>€</small> 950
                  </h1>
                  <ul>
                    <li>
                      <Success>
                        <Check />
                      </Success>
                      Menos de 30m
                      <sup>3</sup>
                    </li>
                    <li>
                      <Danger>
                        <Close />
                      </Danger>
                      De 30m
                      <sup>3</sup> a 50m
                      <sup>3</sup>
                    </li>
                    <li>
                      <Danger>
                        <Close />
                      </Danger>
                      De 50m
                      <sup>3</sup> a 100m
                      <sup>3</sup>
                    </li>
                    <li>
                      <Danger>
                        <Close />
                      </Danger>
                      De 100m
                      <sup>3</sup> a 150m
                      <sup>3</sup>
                    </li>
                    <li>
                      <Danger>
                        <Close />
                      </Danger>
                      De 150m
                      <sup>3</sup> a 200m
                      <sup>3</sup>
                    </li>
                  </ul>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem
              xs={12}
              sm={6}
              md={4}
              lg={2}
              className={classes.pricingItem}
            >
              <Card pricing plain>
                <CardBody pricing plain>
                  <h6 className={classes.cardCategory}>PEQUEÑAS</h6>
                  <h1 className={classes.cardTitle}>
                    <small>€</small> 1100
                  </h1>
                  <ul>
                    <li>
                      <Success>
                        <Check />
                      </Success>
                      Menos de 30m
                      <sup>3</sup>
                    </li>
                    <li>
                      <Success>
                        <Check />
                      </Success>
                      De 30m
                      <sup>3</sup> a 50m
                      <sup>3</sup>
                    </li>
                    <li>
                      <Danger>
                        <Close />
                      </Danger>
                      De 50m
                      <sup>3</sup> a 100m
                      <sup>3</sup>
                    </li>
                    <li>
                      <Danger>
                        <Close />
                      </Danger>
                      De 100m
                      <sup>3</sup> a 150m
                      <sup>3</sup>
                    </li>
                    <li>
                      <Danger>
                        <Close />
                      </Danger>
                      De 150m
                      <sup>3</sup> a 200m
                      <sup>3</sup>
                    </li>
                  </ul>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem
              xs={12}
              sm={6}
              md={4}
              lg={2}
              className={classes.pricingItem}
            >
              <Card pricing plain>
                <CardBody pricing plain>
                  <h6 className={classes.cardCategory}>MEDIANAS</h6>
                  <h1 className={classes.cardTitle}>
                    <small>€</small> 1400
                  </h1>
                  <ul>
                    <li>
                      <Success>
                        <Check />
                      </Success>
                      Menos de 30m
                      <sup>3</sup>
                    </li>
                    <li>
                      <Success>
                        <Check />
                      </Success>
                      De 30m
                      <sup>3</sup> a 50m
                      <sup>3</sup>
                    </li>
                    <li>
                      <Success>
                        <Check />
                      </Success>
                      De 50m
                      <sup>3</sup> a 100m
                      <sup>3</sup>
                    </li>
                    <li>
                      <Danger>
                        <Close />
                      </Danger>
                      De 100m
                      <sup>3</sup> a 150m
                      <sup>3</sup>
                    </li>
                    <li>
                      <Danger>
                        <Close />
                      </Danger>
                      De 150m
                      <sup>3</sup> a 200m
                      <sup>3</sup>
                    </li>
                  </ul>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem
              xs={12}
              sm={6}
              md={4}
              lg={2}
              className={classes.pricingItem}
            >
              <Card pricing plain>
                <CardBody pricing plain>
                  <h6 className={classes.cardCategory}>GRANDES</h6>
                  <h1 className={classes.cardTitle}>
                    <small>€</small> 1850
                  </h1>
                  <ul>
                    <li>
                      <Success>
                        <Check />
                      </Success>
                      Menos de 30m
                      <sup>3</sup>
                    </li>
                    <li>
                      <Success>
                        <Check />
                      </Success>
                      De 30m
                      <sup>3</sup> a 50m
                      <sup>3</sup>
                    </li>
                    <li>
                      <Success>
                        <Check />
                      </Success>
                      De 50m
                      <sup>3</sup> a 100m
                      <sup>3</sup>
                    </li>
                    <li>
                      <Success>
                        <Check />
                      </Success>
                      De 100m
                      <sup>3</sup> a 150m
                      <sup>3</sup>
                    </li>
                    <li>
                      <Danger>
                        <Close />
                      </Danger>
                      De 150m
                      <sup>3</sup> a 200m
                      <sup>3</sup>
                    </li>
                  </ul>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem
              xs={12}
              sm={6}
              md={4}
              lg={2}
              className={classes.pricingItem}
            >
              <Card pricing plain>
                <CardBody pricing plain>
                  <h6 className={classes.cardCategory}>MUY GRANDES</h6>
                  <h1 className={classes.cardTitle}>
                    <small>€</small> 2400
                  </h1>
                  <ul>
                    <li>
                      <Success>
                        <Check />
                      </Success>
                      Menos de 30m
                      <sup>3</sup>
                    </li>
                    <li>
                      <Success>
                        <Check />
                      </Success>
                      De 30m
                      <sup>3</sup> a 50m
                      <sup>3</sup>
                    </li>
                    <li>
                      <Success>
                        <Check />
                      </Success>
                      De 50m
                      <sup>3</sup> a 100m
                      <sup>3</sup>
                    </li>
                    <li>
                      <Success>
                        <Check />
                      </Success>
                      De 100m
                      <sup>3</sup> a 150m
                      <sup>3</sup>
                    </li>
                    <li>
                      <Success>
                        <Check />
                      </Success>
                      De 150m
                      <sup>3</sup> a 200m
                      <sup>3</sup>
                    </li>
                  </ul>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      {/* Pricing 4 END */}
    </div>
  );
}

export default withStyles(pricingStyle)(SectionPricing);
