import pool1 from 'assets/img/public/mathias-adam-327287-unsplash.jpg';
import pool2 from 'assets/img/swimming_pool2.jpg';
import pool3 from 'assets/img/swimming_pool3.jpg';
import pool4 from 'assets/img/public/marion-michele-727293-unsplash.jpg';
import pool5 from 'assets/img/public/suttipong-surak-22764-unsplash.jpg';
import pool6 from 'assets/img/swimming_pool4.jpg';
import pool7 from 'assets/img/public/z-pm-1618054-unsplash.jpg';

const esLocales = {
  solutions: [
    {
      title: 'Construcción',
      subtitle: 'Para piscinas nuevas',
      text: 'Construimos todo tipos de piscinas:',
      items: [
        'Piscinas de obra',
        'Piscinas con skimmer',
        'Piscinas desbordantes de cualquier tipo',
        'Piscinas con lámina armada',
        'Piscinas de poliester',
        'Piscinas a medida',
      ],
      image: pool1,
    },
    {
      title: 'Rehabilitación',
      subtitle: 'Actualiza tu piscina y vuelve a estrenarla',
      text: 'Tanto si tienes una piscina que ha sufrido los efectos del tiempo como si quieres renovarla para darle otro aire, podemos ayudarte',
      items: [
        'Rehabilitación de obra',
        'Rehabilitación con lámina armada',
        'Rehabilitación de cualquier material a mosaíco vítreo “gresite”)',
      ],
      image: pool2,
    },
    {
      title: 'Mantenimiento',
      subtitle: 'El cuidado de tu piscina en las mejores manos',
      text: 'Somos especialistas en química del agua y ofrecemos diferentes opciones para el mantenimiento de su piscina. Desde mantenimientos ordinarios anuales hasta mantenimientos extraordinarios y/o puntuales, así como puestas a punto del agua y las instalaciones.',
      items: [
        'Puesta a punto del agua',
        'Mantenimiento de sistemas hidráulicos',
        'Mantenimiento de sistemas de filtrado',
        'Puesta a punto de sistemas automatizados',
      ],
      text2: 'También realizamos tratamientos en <strong>piscinas públicas</strong> con total adaptación a la legislación vigente.',
      image: pool3,
    },
    {
      title: 'Tratamientos fisicoquímicos',
      subtitle: 'El agua es su salud',
      text: 'Disponemos de conocimientos y herramientas para tener su agua perfectamente equilibrada y en las mejores condiciones higienicosanitarias. Evite accidentes, molestias y dinero teniendo el agua siempre perfecta. ¿Sabía que un agua corrosiva puede dañar la piel y puede generarle problemas en las bombas de la piscina? ¿Sabía que un agua incrustante puede hacer que gastemos mucho más dinero en desinfectante y que el agua se enturbie y se generen deposiciones de cal en el vaso? Deje su piscina en nuestras manos y olvídese de todos estos y muchos otros problemas.',
      items: [
        'Analíticas completas de calidad y salubridad del agua',
        'Tratamientos contra cloraminas',
        'Tratamientos contra fosfatos',
        'Ajuste de dureza del agua',
        'Tratamientos desincrustantes',
        'Desinfección con diferentes sistemas y desinfectantes alternativos al cloro',
        'Tratamientos contra insectos acuáticos',
        'Recuperaciones de aguas en mal estado y/o hibernadas',
        'Potabilización de aguas para consumo humano',
      ],
      image: pool4,
    },
    {
      title: 'Resolución de problemas',
      subtitle: 'Donde los demás ven un problema, nosotros vemos una futura mejora',
      text: 'Si tiene una avería, posible fuga de agua y/o hay algún sistema de la piscina que no funciona correctamente nosotros somos su solución. Contacte con nosotros y le asesoraremos gustosamente sobre todas las posibilidades de las que dispone',
      items: [
        'Detección de fugas en tuberías y/o elementos del vaso',
        'Diagnosis y reparación de sistemas de electrólisis salina (piscinas de sal)',
        'Reparación de problemas en el sistema hidráulico y de filtración',
        'Aguas duras constantes, instalación de sistemas de descalcificación de agua'
      ],
      image: pool5,
    },
    {
      title: 'Automatización y optimización',
      subtitle: 'Su piscina en las mejores condiciones con la mayor comodidad y tranquilidad',
      text: '¿Quiere controlar su piscina desde el Smartphone, tablet o PC?. ¿Quiere gastar menos agua y productos químicos en el mantenimiento de su piscina? Si su respuesta es sí a alguna de estas dos preguntas o tiene alguna duda de todo lo que puede hacer su piscina, nosotros somos los indicados. Instalamos todo tipo de automatizaciones, desde sistemas simples para el control y regulación del pH, hasta domotizaciones en las que usted puede controlar la piscina esté donde esté, <strong>bienvenido a la piscina 2.0</strong>',
      items: [
        'Cálculo, diseño e instalación de sistemas automatizados de control y regulación de los parámetros químicos',
        'Cálculo, diseño e instalación de sistemas de electrólisis salina, pasamos cualquier piscina a piscina salada',
        'Cálculo, diseño e instalación de sistemas de desinfección sin cloro',
        'Instalación de cubiertas manuales o automáticas',
        'Instalación de sistemas de climatización, tanto para calentar como para enfriar',
        'Instalación de sistemas de nado contracorriente',
        'Instalación de fuentes, cascadas, escaleras de INOX, etc.',
        'Estudio de eficiencia hidráulica de su instalación y asesoramiento al respecto',
        'Vidrio filtrante',
        'Tecnología LED en iluminación acuática',
      ],
      image: pool6,
    },
    {
      title: 'Piscinas públicas',
      subtitle: 'En Piscines Cosgaya pensamos en sus clientes como si fuera el nuestro',
      text: 'Conocemos la dificultad de gestionar una actividad, por ese motivo queremos que nos deje la piscina a nosotros. Instalamos todo tipo de accesorios y sistemas en piscinas para piscinas de hoteles, campings, centros deportivos y cualquier otra instalación que según la legislación vigente esté considerada de uso público. Delegue en nosotros las tareas del mantenimiento y puesta en marcha de la piscina y, si va a construir una, no dude en contactar con nosotros para pedir presupuesto. Ofrecemos un servicio excelente a un precio muy razonable, no se arrepentirá.',
      items: [
        'Diseño, cálculo y construcción de piscinas públicas',
        'Rehabilitación del vaso para adaptar a nuevas normativas',
        'Reacondicionamiento del sistema hidráulico para cumplir exigencias legales',
        'Cálculo, diseño e instalación de sistemas de electrólisis salina',
        'Mantenimientos anuales y/o de temporada',
        'Puestas a punto',
      ],
      image: pool7,
    }
  ],
};

export default esLocales;
