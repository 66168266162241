/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Build from "@material-ui/icons/Build";
import Subject from "@material-ui/icons/Subject";
import FormatPaint from "@material-ui/icons/FormatPaint";
import Code from "@material-ui/icons/Code";
import Dashboard from "@material-ui/icons/Dashboard";
import Timeline from "@material-ui/icons/Timeline";
import Group from "@material-ui/icons/Group";
import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import NavPills from "components/NavPills/NavPills.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Muted from "components/Typography/Muted.jsx";
import InfoArea from "components/InfoArea/InfoArea.jsx";
import Badge from "components/Badge/Badge.jsx";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from "@material-ui/core/Slide";
import Instruction from "components/Instruction/Instruction.jsx";

import infoStyle from "assets/jss/material-kit-pro-react/views/promosPageSections/infoStyle.jsx";

import office2 from "assets/img/examples/office2.jpg";
import cardBlog3 from "assets/img/examples/card-blog3.jpg";
import cardProject1 from "assets/img/examples/card-project1.jpg";
import cardProject2 from "assets/img/swimming_pool9.jpg";
import cardProject3 from "assets/img/examples/card-project3.jpg";
import cardProject4 from "assets/img/examples/card-project4.jpg";
import cardProject5 from "assets/img/examples/card-project5.jpg";
import cardProject6 from "assets/img/examples/card-project6.jpg";

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Transition(props) {
  return <Slide direction="down" {...props} />;
}

  class SectionInfo extends React.Component {
    state = {
      showConditionsDialog: false
    };

  handleConditionsDialogOpen = () => {
    this.setState({ showConditionsDialog: true });
  };

  handleConditionsDialogClose = () => {
    this.setState({ showConditionsDialog: false });
  };

  render(){

  const { classes, onPromoClicked, ...rest } = this.props;
  return (
    <div className="cd-section" {...rest}>
    <Dialog
      classes={{
        root: classes.modalRoot,
        paper: classes.modal
      }}
      open={this.state.showConditionsDialog}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => this.handleConditionsDialogClose()}
      aria-labelledby="notice-modal-slide-title"
      aria-describedby="notice-modal-slide-description"
    >
      <DialogTitle
        id="notice-modal-slide-title"
        disableTypography
        className={classes.modalHeader}
      >
        <h4 className={classes.modalTitle}>
          Condiciones de las promociones
        </h4>
      </DialogTitle>
      <DialogContent
        id="notice-modal-slide-description"
        className={classes.modalBody}
      >
        <Instruction
          title="1. Operativa"
          text={
            <div>
            <p>
              Se realizará una visita a las piscinas previamente y se estudiarán las acciones necesarias en cada caso.
            </p>
            <p>
            Se informará al cliente sobre qué mantenimiento es el mejor en función de las necesidades.
            </p>
            <p>
            Los prespuestos realizados son individuales y personalizados a cada cliente. No conllevan ningún coste ni compromiso por parte del cliente.
            </p>
            </div>
          }
          className={classes.instructionNoticeModal}
          imageClassName={classes.imageNoticeModal}
        />
        <Instruction
          title="2. Validez"
          text={
            <div>
            <p>
              Estas promciones serán validas desde el 1 de octubre del 2018 hasta el 1 de abril del 2019.
            </p>
            </div>
          }
          className={classes.instructionNoticeModal}
          imageClassName={classes.imageNoticeModal}
        />
      </DialogContent>
      <DialogActions
        className={classes.modalFooter + " " + classes.modalFooterCenter}
      >
        <Button
          onClick={() => this.handleConditionsDialogClose()}
          color="info"
          round
          className={classes.closeButton}
        >
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
      <div className={`${classes.projects} ${classes.projects4}`}>
        <div className={classes.container}>
        <GridContainer>
          <GridItem
            xs={12}
            sm={8}
            md={8}
            className={`${classes.mlAuto} ${classes.mrAuto} ${
              classes.textCenter
            }`}
          >
            <h2 className={classes.title}>
              Promociones
            </h2>
            <h5 className={classes.description}>
              Aproveche nuestras promociones para obtener nuestros servicios al mejor precio. Si tiene alguna duda no dude en contactarnos.
            </h5>
            <div className={classes.sectionSpace} />
          </GridItem>
        </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={5} className={classes.mrAuto}>
              <InfoArea
                className={classes.info4}
                title="Mano de obra"
                description="Gratis en la instalación de sistemas de electrólisi salina (piscina de sal) y sistemas automatizados de productos químicos."
                icon={()=><FontAwesomeIcon icon={["fas", "paint-roller"]} className={classes.icon} />}
                iconColor="success"
              />
              <InfoArea
                className={classes.info4}
                title="Tratamientos con bromo"
                description="Descuento del 10% al usar este elemento químico mucho más eficiente que el cloro."
                icon={()=><FontAwesomeIcon icon={["fas", "flask-potion"]} className={classes.icon} />}
                iconColor="primary"
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={5} className={classes.mrAuto}>
              <InfoArea
                className={classes.info4}
                title="Sistema Soft Care"
                description="Descuento del 10% al usar este sistema recomendado para pieles atópicas."
                icon={()=><FontAwesomeIcon icon={["fas", "grin-beam"]} className={classes.icon} />}
                iconColor="rose"
              />
            <InfoArea
                className={classes.info4}
                title="Piscinas de sal"
                description="Descubre nuestras promociones en sistemas de electrólisi salina y olvídate del cloro."
                icon={()=><FontAwesomeIcon icon={["fas", "swimming-pool"]} className={classes.icon} />}
                iconColor="info"
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={12} className={classes.seeConditions}>
              <a onClick={this.handleConditionsDialogOpen}><FontAwesomeIcon icon={["fas", "hands-helping"]} className={classes.icon} />Ver condiciones</a>
            </GridItem>
          </GridContainer>
          <hr />
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} className={classes.mrAuto}>
              <InfoArea
                className={classes.infoFull}
                title="Mano de obra"
                description=""
                icon={()=><FontAwesomeIcon icon={["fas", "paint-roller"]} className={classes.icon} />}
                iconColor="success"
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={12} className={classes.mrAuto}>
              <InfoArea
                className={classes.infoFull}
                title=""
                description="Gratis en la instalación de sistemas de electrólisi salina (piscina de sal) y sistemas automatizados de productos químicos."
                icon={()=><FontAwesomeIcon icon={["fal", "angle-right"]} className={classes.icon} />}
                iconColor="gray"
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={12} className={classes.mrAuto}>
            <InfoArea
              className={classes.infoFull}
              title=""
              description="Posibilidad de fraccionar el pago del material en 12 mesos junto al mantenimiento anual."
              icon={()=><FontAwesomeIcon icon={["fal", "angle-right"]} className={classes.icon} />}
              iconColor="gray"
            />
            </GridItem>
            <GridItem xs={12} sm={12} md={12} className={classes.mrAuto}>
            <InfoArea
              className={classes.infoFull}
              title=""
              description="En caso de no contratar el mantenimiento anual, el pago se realizaría como se indique en el presupuesto correspondiente."
              icon={()=><FontAwesomeIcon icon={["fal", "angle-right"]} className={classes.icon} />}
              iconColor="gray"
            />
            </GridItem>
          </GridContainer>
          <hr />
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} className={classes.mrAuto}>
              <InfoArea
                className={classes.infoFull}
                title="Tratamientos con bromo"
                description="Descuento del 10% al usar bromo. El bromo ofrece las siguientes ventajas delante del cloro:"
                icon={()=><FontAwesomeIcon icon={["fas", "flask-potion"]} className={classes.icon} />}
                iconColor="primary"
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={12} className={classes.mrAuto}>
              <InfoArea
                className={classes.infoFull}
                title=""
                description="Mucho menos irritante que el cloro."
                icon={()=><FontAwesomeIcon icon={["fal", "angle-right"]} className={classes.icon} />}
                iconColor="gray"
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={12} className={classes.mrAuto}>
            <InfoArea
              className={classes.infoFull}
              title=""
              description="No genera 'cloraminas', por lo que no son necesarios tratamients de choque."
              icon={()=><FontAwesomeIcon icon={["fal", "angle-right"]} className={classes.icon} />}
              iconColor="gray"
            />
            </GridItem>
            <GridItem xs={12} sm={12} md={12} className={classes.mrAuto}>
            <InfoArea
              className={classes.infoFull}
              title=""
              description="Actua en un rango de pH más amplcio que el cloro, eso favorece en su capacidad como desifectante."
              icon={()=><FontAwesomeIcon icon={["fal", "angle-right"]} className={classes.icon} />}
              iconColor="gray"
            />
            </GridItem>
            <GridItem xs={12} sm={12} md={12} className={classes.mrAuto}>
            <InfoArea
              className={classes.infoFull}
              title=""
              description="Es capaz de mantener la piscina desinfectada a mayores temperaturas que el cloro."
              icon={()=><FontAwesomeIcon icon={["fal", "angle-right"]} className={classes.icon} />}
              iconColor="gray"
            />
            </GridItem>
            <GridItem xs={12} sm={12} md={12} className={classes.mrAuto}>
            <InfoArea
              className={classes.infoFull}
              title=""
              description="Se comporta mejor al eliminar algas en las piscinas."
              icon={()=><FontAwesomeIcon icon={["fal", "angle-right"]} className={classes.icon} />}
              iconColor="gray"
            />
            </GridItem>
          </GridContainer>
          <hr />
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} className={classes.mrAuto}>
              <InfoArea
                className={classes.infoFull}
                title="Sistema Soft Care"
                description="Descuento del 10% en sistemas Soft Care."
                icon={()=><FontAwesomeIcon icon={["fas", "grin-beam"]} className={classes.icon} />}
                iconColor="rose"
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={12} className={classes.mrAuto}>
              <InfoArea
                className={classes.infoFull}
                title=""
                description="Recomendados para pieles atópicas."
                icon={()=><FontAwesomeIcon icon={["fal", "angle-right"]} className={classes.icon} />}
                iconColor="gray"
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={12} className={classes.mrAuto}>
            <InfoArea
              className={classes.infoFull}
              title=""
              description="Desinfecta la piscina combinando rayos UV, alguicidas específicos y perióxido de hidrógeno."
              icon={()=><FontAwesomeIcon icon={["fal", "angle-right"]} className={classes.icon} />}
              iconColor="gray"
            />
            </GridItem>
            <GridItem xs={12} sm={12} md={12} className={classes.mrAuto}>
            <InfoArea
              className={classes.infoFull}
              title=""
              description="Logra mantener el agua totalmente limpia, desinfectada y saludable sin utilizar cloro ni bromo."
              icon={()=><FontAwesomeIcon icon={["fal", "angle-right"]} className={classes.icon} />}
              iconColor="gray"
            />
            </GridItem>
          </GridContainer>
          <hr />
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} className={classes.mrAuto}>
              <InfoArea
                className={classes.infoFull}
                title="Piscinas de sal"
                description="Descubre nuestras promociones en sistemas de electrólisi salina y olvídate del cloro."
                icon={()=><FontAwesomeIcon icon={["fas", "swimming-pool"]} className={classes.icon} />}
                iconColor="info"
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={12} className={classes.mrAuto}>
            <InfoArea
              className={classes.infoFull}
              title=""
              description="Los precios incluyen la instalación del sistema, la puesta en marxa y la sal necesaria para su correcto funcionamiento."
              icon={()=><FontAwesomeIcon icon={["fal", "angle-right"]} className={classes.icon} />}
              iconColor="gray"
            />
            </GridItem>
            <GridItem xs={12} sm={12} md={12} className={classes.mrAuto}>
            <InfoArea
              className={classes.infoFull}
              title=""
              description="Se incluye además la primera analítica para comprobar el equilibrio del agua de forma gratuita."
              icon={()=><FontAwesomeIcon icon={["fal", "angle-right"]} className={classes.icon} />}
              iconColor="gray"
            />
            </GridItem>
            <GridItem xs={12} sm={12} md={12} className={classes.mrAuto}>
            <InfoArea
              className={classes.infoFull}
              title=""
              description="En caso de contratar mantenimiento anual, el pago se podrá fraccionar en 12 meses."
              icon={()=><FontAwesomeIcon icon={["fal", "angle-right"]} className={classes.icon} />}
              iconColor="gray"
            />
            </GridItem>
            <GridItem xs={12} sm={12} md={12} className={classes.mrAuto}>
            <InfoArea
              className={classes.infoFull}
              title=""
              description="El listado de precios es el siguiente:"
              icon={()=><FontAwesomeIcon icon={["fal", "angle-right"]} className={classes.icon} />}
              iconColor="gray"
            />
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );}
}

SectionInfo.propTypes = {
  classes: PropTypes.object.isRequired,
  onPromoClicked: PropTypes.func,
};

export default withStyles(infoStyle)(SectionInfo);
